<template>
  <!-- 删除用户的按钮 -->
  <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeSite(id)">删除</el-button>
</template>

<script>

import { deleteCnzzSite } from '../../../network/statistics'

export default {
  name: 'SiteRemoveDialog',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  methods: {
    // 根据ID删除站点
    removeSite (id) {
      this.$confirm('此操作将删除该站点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCnzzSite(id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.$emit('site-list')
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    }
  }
}
</script>

<style scoped>

</style>
