<template>
  <div>
    <!-- 搜索与添加按钮 -->
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 搜索与添加区域 -->
        <el-input placeholder="站点ID" v-model="listQuery.site_id" clearable @clear="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="addDialogVisible = true">添加站点</el-button>
      </el-col>
    </el-row>

    <!-- 添加站点的对话框 -->
    <el-dialog
      title="添加站点"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose">
      <el-form :model="addSiteForm" :rules="addSiteFormRules" ref="addSiteFormRef" label-width="90px">
        <el-form-item label="CNZZ ID" prop="cnzz_id">
          <el-input v-model="addSiteForm.cnzz_id"></el-input>
        </el-form-item>
        <el-form-item label="站点ID" prop="site_id">
          <el-input v-model="addSiteForm.site_id"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="manager">
          <el-input v-model="addSiteForm.manager"></el-input>
        </el-form-item>
        <el-form-item label="结算方式" prop="settle">
          <el-select v-model="addSiteForm.settle" style="width:100%" clearable placeholder="结算方式选择">
            <el-option
              v-for="item in settleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单价格" prop="unit_price">
          <el-input v-model.number="addSiteForm.unit_price"></el-input>
        </el-form-item>
        <el-form-item label="补充说明" prop="comment">
          <el-input v-model="addSiteForm.comment" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSite">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { addCnzzSite } from '../../../network/statistics'

export default {
  name: 'SiteSearchAndAdd',
  props: {
    listQuery: {
      type: Object,
      default () {
        return {
          site_id: '',
          page: 1, // 当前页
          size: 10 // 每页显示多少条数据
        }
      }
    }
  },
  data () {
    return {
      addDialogVisible: false,
      // 添加站点表单的数据
      addSiteForm: {
        cnzz_id: '',
        site_id: '',
        manager: '',
        settle: 0,
        unit_price: 0,
        comment: ''
      },
      addSiteFormRules: {
        cnzz_id: [
          {
            required: true,
            message: '请输入CNZZ ID',
            trigger: 'blur'
          }
        ],
        site_id: [
          {
            required: true,
            message: '请输入站点ID',
            trigger: 'blur'
          }
        ]
      },
      settleOptions: [
        {
          label: '通量',
          value: 0
        },
        {
          label: '拉起量',
          value: 1
        }
      ]
    }
  },
  methods: {
    // 点击搜索按钮时，通知外部组件重新获取用户列表
    search () {
      this.$emit('site-list')
    },

    // 监听弹框的关闭事件
    addDialogClose () {
      this.$refs.addSiteFormRef.resetFields()
    },

    addSite () {
      this.$refs.addSiteFormRef.validate(valid => {
        if (!valid) return
        addCnzzSite(this.addSiteForm).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('添加站点失败', 'error')
          }
          this.alertMessage('添加站点成功', 'success')
          this.addDialogVisible = false
          this.$emit('site-list')
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
