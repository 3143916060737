<template>
  <el-table class="custom-site-table" :data="siteList" border stripe>
    <!-- 基本信息 -->
    <el-table-column type="index" label="#"></el-table-column>
    <el-table-column prop="site_id" label="站点ID"></el-table-column>
    <el-table-column prop="manager" label="负责人"></el-table-column>
    <el-table-column prop="settle" label="结算方式">
      <template slot-scope="scope">{{ scope.row.settle | formatSettleType }}</template>
    </el-table-column>
    <el-table-column prop="unit_price" label="单价">
    </el-table-column>
    <!-- 操作 -->
    <el-table-column label="操作" width="275px" class="btns">
      <template slot-scope="scope">
        <el-button type="success" class="custom-button" icon="el-icon-link" size="mini"
                   @click="fastView(scope.row.cnzz_id)">快捷访问
        </el-button>
        <site-edit-dialog :id="scope.row.id" @site-list="updateSiteList"/>
        <site-remove-dialog :id="scope.row.id" @site-list="updateSiteList"/>
      </template>
    </el-table-column>

  </el-table>
</template>

<script>

import SiteEditDialog from './SiteEditDialog'
import SiteRemoveDialog from './SiteRemoveDialog'

export default {
  name: 'SiteTable',
  components: {
    SiteEditDialog,
    SiteRemoveDialog
  },
  filters: {
    formatSettleType (value) {
      if (value === 0) {
        return '通量'
      } else if (value === 1) {
        return '拉起量'
      } else {
        return '通量'
      }
    }
  },
  props: {
    siteList: {
      type: Array,
      default () {
        return []
      }
    },
    listQuery: {
      type: Object,
      default () {
        return {
          name: '',
          page: 1,
          size: 10
        }
      }
    }
  },
  methods: {
    // 监听子组件发射的事件，获得最新的siteList和total信息
    updateSiteList () {
      this.$emit('site-list')
    },
    fastView (siteId) {
      const url = 'http://new.cnzz.com/v1/login.php?siteid=' + siteId
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
.custom-site-table {
  margin-top: 20px;
}

.custom-button {
  display: inline-block;
  margin-right: 5px;
}
</style>
