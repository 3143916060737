import { render, staticRenderFns } from "./SiteSearchAndAdd.vue?vue&type=template&id=1a5f847e&scoped=true"
import script from "./SiteSearchAndAdd.vue?vue&type=script&lang=js"
export * from "./SiteSearchAndAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a5f847e",
  null
  
)

export default component.exports