<template>
  <div class="editDialog">
    <!-- 修改站点的按钮 -->
    <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(id)">编辑</el-button>

    <!-- 修改站点的对话框 -->
    <el-dialog
      title="修改站点"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClose">
      <el-form :model="editSiteForm" ref="editSiteFormRef" label-width="70px">
        <el-form-item label="CNZZ ID">
          <el-input v-model="editSiteForm.cnzz_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="站点ID">
          <el-input v-model="editSiteForm.site_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="manager">
          <el-input v-model="editSiteForm.manager"></el-input>
        </el-form-item>
        <el-form-item label="结算方式" prop="settle">
          <el-select v-model="editSiteForm.settle" style="width:100%" clearable placeholder="结算方式选择">
            <el-option
              v-for="item in settleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单价" prop="unit_price">
          <el-input v-model.number="editSiteForm.unit_price"></el-input>
        </el-form-item>
        <el-form-item label="补充说明" prop="comment">
          <el-input v-model="editSiteForm.comment" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSiteInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {
  getCnzzSite,
  updateCnzzSite
} from '../../../network/statistics'

export default {
  name: 'SiteEditDialog',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      editDialogVisible: false,
      editSiteForm: {
        cnzz_id: '',
        site_id: '',
        manager: '',
        settle: 0,
        unit_price: 0,
        comment: ''
      },
      settleOptions: [
        {
          label: '通量',
          value: 0
        },
        {
          label: '拉起量',
          value: 1
        }
      ]
    }
  },
  methods: {
    // 展示编辑用户的对话框，并获取当前用户的数据
    showEditDialog (id) {
      this.editDialogVisible = true
      getCnzzSite(id).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取站点信息失败', 'error')
        }
        this.editSiteForm = result.data
      })
    },

    // 监听编辑用户的对话框的关闭事件
    editDialogClose () {
      this.$refs.editSiteFormRef.resetFields()
    },

    // 点击编辑用户的“确定”按钮
    updateSiteInfo () {
      this.$refs.editSiteFormRef.validate(valid => {
        if (!valid) return
        console.log('update site value:', this.editSiteForm)
        updateCnzzSite(this.editSiteForm).then(res => {
          // 更新失败
          if (res.status !== 200) {
            return this.alertMessage('更新站点信息失败', 'error')
          }
          // 提示更新成功
          this.alertMessage('更新站点信息成功', 'success')
          // 关闭对话框
          this.editDialogVisible = false
          // 重新获取用户列表
          this.$emit('site-list')
        })
      })
    }
  }
}
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
</style>
