<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>数据统计</template>
      <template v-slot:secondMenu>站点管理</template>
    </breadcrumb-nav>
    <!-- 卡片视图-->
    <el-card class="box-card">
      <!-- 顶部搜索与添加按钮 -->
      <site-search-and-add :listQuery="listQuery" @site-list="updateSiteList"/>

      <!-- 展示的表格 -->
      <site-table :site-list="siteList" :list-query="listQuery" @site-list="updateSiteList"/>

      <!-- 分页 -->
      <site-pagination :list-query="listQuery" :total="total" @page-size-change="handleSizeChange"
                       @current-page-change="handleCurrentChange"/>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import SiteSearchAndAdd from './childComponents/SiteSearchAndAdd'
import SiteTable from './childComponents/SiteTable'
import SitePagination from './childComponents/SitePagination'

import {
  getCnzzSiteList
} from '../../network/statistics'

export default {
  name: 'Site',
  components: {
    BreadcrumbNav,
    SiteSearchAndAdd,
    SiteTable,
    SitePagination
  },
  data () {
    return {
      listQuery: {
        site_id: '',
        page: 1,
        size: 10
      },
      siteList: [],
      total: 0
    }
  },
  created () {
    this.getSiteList()
  },
  methods: {
    // 获取用户列表
    getSiteList () {
      console.log('site list query:', this.listQuery)
      getCnzzSiteList(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('cnzz站点列表获取失败', 'error')
        }
        this.siteList = result.data.data
        this.total = result.data.total
      })
    },

    // 监听子组件发射的事件，获得最新的userList和total信息
    updateSiteList () {
      this.getSiteList()
    },

    // 监听pageSize改变的事件，并发送新的网络请求
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getSiteList()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getSiteList()
    }
  }
}
</script>

<style scoped>
</style>
